.notify {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.14);
    background-color: #fff;
    padding: 20px 40px;
    margin-bottom: 30px;

    &-body {
        display: flex;
        align-items: center;
    }

    &-text {
        // Стили текстовой обертки
    }

    &-icon {
        flex: 0 0 auto;
        width: 75px;
        height: 45px;
        fill: $color-primary;
        margin-right: 30px;

        &.done{
            fill: #7ed321;
        }
    }

    &-title {
        font-size: 24px;
        font-weight: 700;
        color: $color-text;
        line-height: 1.5;
    }

    &-desc {
        color: #9b9b9b;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
    }

    &-button {
        width: 275px;
        height: 50px;
        border-radius: 22px;
        background-color: #f4f4f4;
        position: relative;
        transition: $time;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 22px;
            background-color: #fff;
            color: $color-hover;
            border: 1px solid $color-hover;
            font-size: 16px;
            font-weight: 700;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            transition: $time;
        }
        &:hover{
            background-color: transparent;
            span{
                color: #fff;
                background-color: $color-hover;
            }
        }
    }
}

@media(max-width: 767px) {
    .notify {
        flex-direction: column;
        align-items: flex-start;

        &-body {
            margin-bottom: 15px;
        }

        &-icon {
            margin-right: 15px;
        }
    }
}

@media(max-width: 575px) {
    .notify {
        padding: 15px 20px;

        &-body {
            flex-direction: column;
            align-items: flex-start;
        }

        &-icon {
            margin-right: 15px;
        }
    }
}

@media(max-width: 490px) {
    .notify-button{
        width: 100%;
    }
}