.filters{
	&__form{
		background-color: #fff;
		border: 1px solid #e5e5e5;
		margin-bottom: 30px;
	}
	&__footer{
		padding: 16px 12px;
		&-result{
			color: #373a3c;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			padding-bottom: 15px;
		}
		&-buttons{
			display: flex;
			justify-content: space-between;
			input{
				margin-bottom: 5px;
				cursor: pointer;
				height: 30px;
				border-radius: 15px;
				background-color: $color-primary;
				border-color: $color-primary;
				flex: 0 0 calc(100% / 2 - 5px);
				color: #ffffff;
				font-size: 12px;
				font-weight: 400;
				line-height: 13px;
				&:hover{
					background-color: $color-hover;
					border-color: $color-hover;
				}
			}
			input[type="reset"]{
				border: 1px solid #cccccc;
				background-color: #ffffff;
				border-radius: 15px !important;
				color: #373a3c;
				transition: .3s;
				&:hover{
					color: $color-hover;
					border-color: $color-hover;
				}
			}
		}
	}

	&__mobile-footer{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100px;
		padding: 16px 12px;
		border-top: 1px solid #e5e5e5;
	}
	&__mobile-result{
		color: #373a3c;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		padding-bottom: 15px;
	}
	&__mobile-controllers{
		display: flex;
		justify-content: space-between;
		button{
			margin-bottom: 5px;
			cursor: pointer;
			height: 30px;
			flex: 0 0 calc(100% / 2 - 5px);
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			background-color: $color-primary;
			border-color: $color-primary;
		}
	}
	&__mobile-reset{
		color: #373a3c;
		border: 1px solid #ccc !important;
		background-color: #fff !important;
		border-radius: 4px;
		transition: .3s;
		&:hover{
			color: $color-primary;
			border-color: $color-primary;
		}
	}

	input:not([type="submit"]){
		width: 100%;
		max-width: 100%;
		height: 30px;
		border: 1px solid #ccc;
		background-color: #fff;
		color: #373a3c;
		font-size: 14px;
		font-weight: 400;
		line-height: 21px;
		padding: 0 16px;
		&::placeholder{
			color: #9b9b9b;
			font-size: 14px;
		}
	}
}



@media (max-width: 1199px) {
	.filters{
		background-color: #fff;
		position: fixed;
		z-index: 800;
		width: 270px;
		height: 100%;
		right: 0;
		top: 0;
		overflow-y: auto;
		transform: translateX(110%);
		transition: .3s;
		&.opened{
			transform: translateX(0%);
		}
		&__footer{
			display: none;
		}
		&__mobile-wrapper{
			height: calc(100% - 100px);
			overflow-y: auto;
		}
	}
}