.product-card {
    display: block;
    height: 425px;
    margin-bottom: 28px;
    position: relative;
    &__wrapper{
        height: 400px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
        background-color: #ffffff;
        transition: $time;
    }
    &__thumb{
        position: relative;
        max-height: 220px;
        flex: 0 0 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        svg{
            fill: $color-primary;
        }

        &-badges{
            position: absolute;
            display: flex;
            flex-direction: row;
            left: 15px;
            top: 15px;
            div{
                padding: 3px 8px;
                font-size: 12px;
                color: #fff;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-right: 3px;
                &.hit{
                    background-color: #ff714b;
                }
                &.sale{
                    background-color: #ffc107;
                }
                &.new{
                    background-color: #86BA4B;
                }
            }
        }
    }
    &__body{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 20px 45px;
        span{
            color: $color-text;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            display: block;
            max-height: 85px;
        }
        p{
            display: flex;
            justify-content: space-between;
            align-items: center;
            em{
                color: #a9a9a9;
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
            }
            b{
                color: #36a7ef;
                font-weight: 900;
                font-size: 18px;
            }
        }
    }
    &__button{
        position: relative;
        bottom: 25px;
        // background-color: #f4f4f4;
        border-radius: 32px;
        left: 0;
        right: 0;
        width: 170px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transition: $time;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-hover;
            font-size: 16px;
            font-weight: 700;
            border: 1px solid $color-hover;
            background-color: #ffffff;
            width: 160px;
            height: 45px;
            border-radius: 22px;
            transition: $time;
        }
    }

    &:hover{
        .product-card__wrapper{
            box-shadow: 0 8px 22px rgba(0, 0, 0, 0.26);
        }
        .product-card__button{
            background-color: transparent;
            span{
                background-color: $color-hover;
                color: #fff;
            }
        }
    }

    .loading-blocks &:after {
        content: "";
        position: absolute;
        z-index: 99;
        top: -1px;
        left: -1px;
        height: calc(100% + 2px);
        width: calc(100% + 2px);
        background-image: url("../images/card_big.jpg");
        background-size: 100% 100%;
    }
}

@media (max-width : 575px) {
    .product-card{
        margin-bottom: 15px;
    }
}