.seo-scroller{
	padding: 75px 0 50px;
	background-color: #fff;
	&__image{
		height: 600px;
		img{
			width: 95%;
			height: 95%;
			object-fit: contain;
		}
	}
	&__content{
		height: 600px;
		overflow: visible !important;
	}
	.os-content{
		padding: 0 20px 0 0 !important;
	}
	.os-scrollbar{

		&-vertical{
			.os-scrollbar-track{
				position: absolute;
				right: 1px;
				width: 2px;
				background: #dee2e6 !important;
				.os-scrollbar-handle{
					width: 4px;
   					margin: 0px 0px 0px -1px;
				}
			}
		}
	}
}

@media (max-width : 767px) {
	.seo-scroller{
		padding: 20px 0;
		&__image{
			height: 320px;
			margin: 0 0 20px;
		}
		&__content{
			height: 320px;
		}
	}
}

@media(max-width: 575px){
	.seo-scroller{
		&__image{
			height: 190px;
		}
		&__content{
			height: 280px;
		}
	}
}