.header{
    border-bottom: 2px solid $color-primary;
    background-color: #fff;
    &-wrapper{
        display: flex;
        flex-direction: row;
    }

    &-logo{
        display: flex;
        align-items: center;
        margin-right: 40px;
        svg{
            fill: $color-primary;
            width: 152px;
            height: 88px;
        }
    }

    &-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        &__top, &__bottom{
            width: 100%;
            display: flex;
            flex-direction: row;
        }
        &__top{
            justify-content: space-between;
            border-bottom: 1px solid $color-border;
        }
    }
}

.main-menu{
    ul{
        display: flex;
        flex-direction: row;
        li{
            a{
                display: inline-block;
                color: $color-text;
                font-size: 16px;
                font-weight: 400;
                transition: $time;
                padding: 21px 18px;
                &:hover{
                    color: $color-hover;
                }
            }
        }
    }
}

.main-contacts{
    display: flex;
    flex-direction: row;
    align-items: center;
    a{
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 20px;
        svg{
            width: 16px;
            height: 16px;
            fill: $color-primary;
            margin-right: 10px;
        }
        span{
            display: inline-block;
            border-bottom: 1px solid $color-primary;
            color: #828282;
            font-size: 16px;
            font-weight: 400;
        }
        b{
            color: $color-text;
            font-size: 16px;
            font-weight: 900;
        }
    }
    &__phone{
        span{
            transition: $time;
        }
        &:hover{
            span{
                color: $color-primary;
                border-color: $color-primary;
            }
        }
    }
    &__call{
        width: 200px;
        height: 40px;
        border-radius: 22px;
        border: 1px solid $color-hover;
        background-color: #ffffff;
        margin-left: 40px;
        position: relative;
        overflow: hidden;
        &::after{
            content: 'Перезвонить мне';
            display: none;
            justify-content: center;
            align-items: center;
            background-color: $color-hover;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            color: #ffffff;
            font-size: 16px;
            font-weight: 700;
        }
        &:hover::after{
            display: flex;
        }
    }
}

.catalog-button{
    position: relative;
    cursor: pointer;
    &__link{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 17px 50px 19px 18px;
        border-right: 1px solid $color-border;
        svg{
            fill: $color-primary;
            margin-right: 15px;
            width: 30px;
            height: 30px;
            transition: $time;
        }
        b{
            color: $color-text;
            font-size: 18px;
            transition: $time;
        }
        &::after{
            transition: $time;
            content: '';
            display: block;
            transform: rotate(45deg);
            border-bottom: 1px solid #c5c5c5;
            border-right: 1px solid #c5c5c5;
            height: 10px;
            width: 10px;
            right: 28px;
            top: calc(50% - 9px);
            position: absolute;
        }
    }

    &::after{
        content: '';
        display: block;
        background-color: transparent;
        position: absolute;
        height: 50px;
        width: 100%;
        bottom: -35px;
    }

    .catalog-menu, .catalog-submenu {
        position: absolute;
        background-color: #fff;
        border: 1px solid #ddd;
        border-top: none;
    }

    .catalog-menu {
        display: none;
        z-index: 100;
        top: 103%;
        left: 0;
        width: 253px;
        &-item {
            display: block;
            &.all-cats{
                transition: $time;
                background-color: $color-hover;
                .catalog-menu-item__text{
                    color: #fff !important;
                }
                &:hover{
                    background-color: $color-primary;
                }

            }
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $color-text;
                transition: $time;
            }

            &__arrow {
                width: 6px;
                height: 9px;
                fill: #999;
                transform: rotate(180deg);
                transition: $time;
            }

            &:hover {
                .catalog-submenu {
                    display: block;
                }
            }
        }

        &-link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 20px;
            &:hover{
                .catalog-menu-item__text{
                    color: $color-hover;
                }
                .catalog-menu-item__arrow{
                    fill: $color-hover;
                }
            }
        }
    }

    .catalog-submenu {
        display: none;
        z-index: 105;
        top: 0;
        left: 100%;
        width: 724px;
        min-height: 100%;
        padding: 8px 0;

        &-link {
            display: block;
            max-width: 290px;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            color: $color-text;
            transition: $time;
            padding: 8px 25px;

            &:hover {
                color: $color-hover;
            }
        }
    }
    &:hover{
        svg{fill: $color-hover;}
        p{
            b, span{
                color: $color-hover;
            }
        }
    }
    &:hover{
        .catalog-button__link svg{
            fill: $color-hover;
        }
        .catalog-button__link b{
            color: $color-hover;
        }
        .catalog-button__link::after{
            border-color: $color-hover;
        }
        .catalog-menu{
            display: block;
        }
    }
}

.search-form{
    flex: 1;
    border-right: 1px solid $color-border;
    position: relative;
    padding: 0 25px;
    input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #ababab;
        font-size: 15px;
        font-weight: 400;
    }
    button{
        background-color: transparent;
        border: none;
        outline: none;
        background-image: url('../images/search.png');
        background-position: center;
        background-repeat: no-repeat;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 28px;
        top: calc(50% - 7px);
        cursor: pointer;
    }
    &__ajax-search{
        padding: 10px 20px 0;
        
        > ul:not(.os-host){
            display: none;
            border: 1px solid #ccc;
            background-color: #fff;
            position: absolute;
            z-index: 200;
            left: -1px;
            top: calc(100% + 2px);
            width: calc(100% + 2px);
            max-height: 500px;
            overflow-y: scroll;
            li{
                a, span{
                    display: block;
                    padding: 8px 20px;
                    color: #9b9b9b;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 21px;
                    background-color: #f5f5f5;
                    transition: .2s;
                    &:hover{
                        color: $color-general;
                    }
                }
            }

            ul{
                padding: 2px 0;
                li{
                    a{
                        
                        padding: 5px 20px;
                        color: #373a3c;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        background-color: transparent;
                        &:hover{color: $color-general;}
                    }
                }
            }
        }
    }
}

.cart-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 205px;
    justify-content: center;
    svg{
        fill: $color-primary;
        transition: $time;
        width: 31px;
        height: 28px;
        margin-right: 16px;
    }
    p{
        b{
            display: block;
            color: $color-text;
            font-size: 16px;
            font-weight: 700;
            font-size: 18px;
            transition: $time;
            &:empty::after{
                content: 'Корзина';
                position: relative;
            }
            &:not(:empty)::after{
                content: ' руб.';
                position: relative;
            }
        }
        span{
            transition: $time;
            color: #828282;
            font-size: 13px;
            font-weight: 400;
        }
    }
    &:hover{
        svg{fill: $color-hover;}
        p{
            b, span{
                color: $color-hover;
            }
        }
    }
}

.header-mobile{
    display: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    &__left,
    &__right{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__left{
        .modile-menu-btn{
            width: 25px;
            height: 18px;
            position: relative;
            margin: 15px 25px 15px 10px;
            transition: .5s ease-in-out;
            cursor: pointer;
            span{
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                background: $color-primary;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: .25s ease-in-out;

                &:nth-child(1){
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3){
                    top: calc(50% - 1px);
                }

                &:nth-child(4){
                    bottom: 0;
                }
            }
            &.opened{
                span{
                    &:nth-child(1),
                    {
                        top: calc(50% - 1px);
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2){
                        transform: rotate(45deg);
                    }

                    &:nth-child(3){
                        transform: rotate(-45deg);
                    }

                    &:nth-child(4){
                        bottom: calc(50% - 1px);
                        width: 0%;
                        left: 50%;
                    }
                }
            }
        }
        a{
            display: block;
            svg{
                fill: $color-primary;
                width: 110px;
                height: 55px;
            }
        }
    }
    &__right{
        svg{
            fill: $color-primary;
            width: 20px;
            height: 20px;
            margin: 10px;
        }
        .mobile-search-trigger{

        }
        .mobile-cart-link{
            position: relative;
            .icon{
                display: block;
            }
            .cart_count{
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -3px;
                right: 0px;
                padding: 0 5px;
                border-radius: 3px;
                background-color: $color-hover;
                color: #fff;
                font-size: 10px;
                font-weight: 700;
            }
        }
    }
}

@media(max-width: 1199px){
    .header-wrapper{
        display: none;
    }
    .header-mobile{
        display: flex;
    }
}