.category-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 270px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    transition: $time;
    margin-bottom: 30px;
    overflow: hidden;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 30px;
    img{
        max-width: 275px;
        margin: 0 auto;
    }
    span{
        color: $color-text;
        font-size: 24px;
        font-weight: 400;
        padding: 0 30px;
    }
    &::before{
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: $color-primary;
        border-radius: 50%;
        top: 20px;
        left: 20px;
        transition: $time;
    }
    &:hover{
        box-shadow: 0 14px 15px rgba(0, 0, 0, 0.18);
        &::before{
            background-color: $color-hover;
        }
    }

    &-more{
        background-image: url('../images/category-card-more.jpg');
        &::before{
            width: 110px;
            height: 110px;
            right: -22px;
            bottom: -19px;
            top: auto;
            left: auto;
             background-image: url('../images/more-arrow.png');
            background-position: 39px 43px;
            background-repeat: no-repeat;
        }
    }
}

@media (max-width : 575px) {
    .category-card{
        margin-bottom: 15px;
        height: 160px;
    }
}