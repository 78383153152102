.cart {
    &-table {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        overflow: hidden;

        &-head {
            display: flex;
            align-items: center;
            height: 30px;
            border-bottom: 1px solid $color-border;
            padding-left: 20px;

            &__title {
                color: $color-primary;
                font-size: 13px;
                font-weight: 700;
            }
        }

        &-row {
            display: flex;
            width: 100%;
            height: 100%;
        }

        &-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 100%;
            
            &_product{
                flex: 0 0 55%;
            }

            &_price {
                flex: 0 0 12%;
            }

            &_count {
                flex: 0 0 15%;
            }

            &_total {
                flex: 0 0 12%;
            }

            &_delete {
                flex: 0 0 auto;
            }
        }
    }

    &-desc {
        border-radius: 6px;
        border: 1px dashed #e5e5e5;
        background-color: #ffffff;
        padding: 14px 30px;
        margin-top: 20px;
        background-color: #fff;
        color: #999999;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
    }

    &-order {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 32px;

        &-btn {
            margin-left: 30px;
            min-width: 270px;
            height: 45px;
            border-radius: 22px;
            border: 1px solid $color-hover;
            background-color: $color-hover;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            transition: $time;
            &:hover{
                background-color: transparent;
                color: $color-hover;
            }
        }
    }

    &-text {
        display: inline-flex;
        align-items: flex-end;
        font-weight: 400;
        font-size: 16px;
        color: #373a3c;
    }

    &-total, &-currency {
        font-weight: 700;
        font-size: 30px;
        margin-left: 7px;
        margin-bottom: -2px;
    }
}

@media(max-width: 1199px) {
    .cart {
        &-order {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 20px;

            &-btn {
                margin-top: 10px;
                margin-left: 0;
            }
        }
    }
}

@media(max-width: 767px) {
    .cart {
        &-table {
            &-head {
                display: none;
            }

            &-row {
                flex-direction: column;
            }

            &-col {
                flex: 0 1 auto;
                align-items: center;
                width: 100%;
                height: auto;
                padding: 5px 15px;

                &_product {
                    padding: 15px 15px 5px;
                }

                &_delete {
                    padding: 0;
                }
            }
        }
    }
}
