.order-items{
	background-color: #fff;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	&__header{
		padding: 14px 20px;
		border-bottom: 1px solid #e5e5e5;
		p{
			color: $color-primary;
			font-size: 18px;
			font-weight: 700;
		}
	}
	&__title{
		display: block;
		padding: 12px 20px;
		border-bottom: 1px solid #ddd;
		color: #373a3c;
		font-size: 18px;
		font-weight: 600;
	}
	&__item{
		display: flex;
		justify-content: space-between;
		padding: 17px 20px 10px;
		&-info{
			max-width: 60%;
			span{
				display: block;
				color: #373a3c;
				font-size: 14px;
				font-weight: 400;
				line-height: 18px;
				padding: 2px 0 0;
			}
		}
		&-price{
			span{
				display: block;
				color: #373a3c;
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	&__delivery-type{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px 20px;
		margin-top: 15px;
		background-color: $color-primary;
		span{
			color: #fff;
			font-size: 14px;
		}
		b{
			color: #fff;
			font-size: 16px;
		}
	}
	&__final-price{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 14px 20px;
		span{
			color: #373a3c;
			font-size: 16px;
			font-weight: 700;
		}
		b{
			color: $color-primary;
			font-size: 20px;
			font-weight: 700;
		}
	}
}
@media(max-width: 1199px){
	.revers{
		flex-direction: column-reverse;
	}
	.order-items{
		margin-bottom: 15px;
	}
}
@media(max-width: 767px){
	.order-items{
		&__title{
			padding: 8px 15px;
			font-size: 18px;
		}
		&__item{
			padding: 12px 15px 0;
			&-price{
				span{
					text-align: right;
				}
			}
		}
		&__delivery-type{
			padding: 14px 15px 10px;
		}
		&__final-price{
			padding: 10px 15px;
		}
	}
}

@media(max-width: 575px){
	.order-items{
		&__item{
			flex-direction: column;
			align-items: flex-start;
			&-info{
				max-width: none;
			}
		}
	}
}