.catalog-categories {
	padding: 0 15px 15px;
	&__link{
		display: block;
		color: #373a3c;
    	font-size: 16px;
    	font-weight: 400;
    	transition: $time;
    	&.active{
    		color: $color-hover;
    	}
    	&:hover{
    		color: $color-hover;
    	}
	}
}