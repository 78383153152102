.sidebar-helper{
	display: block;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 320px;
	max-width: 270px;
	overflow: hidden;
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.84;
		background-color: $color-primary;
	}
	&__wrapper{
		position: absolute;
		z-index: 2;
		bottom: 45px;
		left: 25px;
	}
	&__title{
		display: block;
		color: #ffffff;
		font-size: 36px;
		font-weight: 700;
		line-height: 42px;
		margin-bottom: 20px;
	}
	&__button{
		width: 200px;
		height: 40px;
		border-radius: 22px;
		background-color: $color-hover;
		border: 1px solid $color-hover;
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		transition: $time;
		&:hover{
			background-color: transparent;
			border-color: #fff;
		}
	}
}