.gallery-item {
	padding: 10px 0 22px;
	&-thumbnail {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		margin: 0 0 10px;
		border: 1px solid $color-border;
		background-position: center;
		background-size: cover;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center center;
			background-color: $color-primary;
			background-size: 30px 30px;
			background-image: $whiteZoomIcon;
			transition: .3s;
		}
		&:hover:after {
			opacity: 0.5;
		}
	}
	&-sign {
		p {
			display: block;
			@include font-sign;
		}
	}
}




@media(max-width: 767px) {
	@import "../../media-mixins";
	
	.gallery-item {
		padding: 5px 0;
		&-thumbnail {
			margin: 0 0 5px;
		}
		&-sign {
			p {
				@include font-sign;
			}
		}
	}
}