@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Light.eot');
    src: url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato/Lato-Light.woff') format('woff'),
         url('../fonts/Lato/Lato-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Regular.eot');
    src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato/Lato-Regular.woff') format('woff'),
         url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Bold.eot');
    src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato/Lato-Bold.woff') format('woff'),
         url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Black.eot');
    src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato/Lato-Black.woff') format('woff'),
         url('../fonts/Lato/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
    height: 100%;
    &.opened {
		overflow: hidden;
	}
}

body {
    min-width: 320px;
    height: 100%;
    margin: 0;
    padding: 0;
	font-family: Lato, sans-serif;
	&.opened {
		overflow: hidden;
        margin-right: 17px;
	}
}

::-webkit-input-placeholder { color: $color-placeholder; }
::-moz-placeholder          { color: $color-placeholder; }
:-moz-placeholder           { color: $color-placeholder; }
:-ms-input-placeholder      { color: $color-placeholder; }

ol, ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
    line-height: normal;
    margin: 0;
    padding: 0;
}

a {
    color: $color-text;
    text-decoration: none;
    &:hover {
        color: $color-general;
        text-decoration: none;
    }
}

// Базовая обертка сайта
.base {
    &-wrapper, &-container {
        width: 100%;
        background-color: #f4f4f4;
    }
    
    &-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &-container {
        flex: 1  auto;
        overflow: initial;
    }

    &-page {
        padding-bottom: 90px;
        background-color: #f4f4f4;
    }
}

// Элементы темы
.show-after-load{
    visibility: hidden;
}
.simple-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.simple-form{
    height: 450px;
    padding-top: 90px;
    background-color: $color-hover !important;
    position: relative;
    &::before{
        content: 'Технополиком';
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 9;
	    width: 100%;
	    text-align: center;
	    font-size: 134px;
	    line-height: 140px;
	    text-transform: uppercase;
	    color: #fff;
	    opacity: 0.2;
	    font-weight: 300;
    }
    &__left{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 49.5vw;
        background-image: url('../images/form-left.jpg'); 
    }
    &__content{
        padding-left: 77px;
    }
    b{
        color: #ffffff;
        font-size: 43px;
        line-height: 52px;
        padding-right: 12px;
        display: inline-block;
    }
    span{
        color: #ffffff;
        font-size: 27px;
        font-weight: 400;
        display: inline-block;
    }
    p.personal{
        opacity: 0.5;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        padding-top: 15px;
        a.personal{
            display: inline-block;
            color: #ffffff;
            border-bottom: 1px solid #fff;
        }
    }
    form{
        text-align: center;
        position: relative;
        padding: 40px 3px 0;
        input[type=submit]{
            width: 430px;
            height: 60px;
            border-radius: 30px;
            background-color: #36a7ef;
            margin: 0 auto;
            color: #ffffff;
            font-size: 18px;
            font-weight: 900;
            border: 1px solid #36a7ef;
            outline: none;
            cursor: pointer;
            transition: $time;
            &:hover{
                background-color: transparent;
                border-color: #fff;
            }
        }
        &.load{
            .load{
                display: flex;
            }
        }
        &.success{
            .success{
                display: flex;
            }
        }
    }
    &__group{
        position: relative;
        margin-bottom: 35px;
        label{
            color: #ffffff;
            font-size: 20px;
            font-weight: 700;
            position: absolute;
            margin-bottom: 0;
            left: 0px;
            top: 5px;
            cursor: text;
            line-height: 20px;
            transition: $time;
            &.active{
                top: -18px;
                font-size: 16px;
            }
        }
        input{
            color: #ffffff;
            font-size: 20px;
            font-weight: 700;
            border: none;
            outline: none;
            width: 100%;
            background-color: transparent;
            border-bottom: 1px solid #fff;

        }
    }
}

div.load, div.success{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .circularG{
        z-index: 9;
    }
    b{
        z-index: 9;
    }
    &::after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $color-hover;
        opacity: 0.8;
        width: 100%;
        height: 100%;
    }
}

#circularG{
    position:relative;
    width:58px;
    height:58px;
    margin: auto;
}

.circularG{
    position:absolute;
    background-color: $color-primary;
    width:14px;
    height:14px;
    border-radius:9px;
        -o-border-radius:9px;
        -ms-border-radius:9px;
        -webkit-border-radius:9px;
        -moz-border-radius:9px;
    animation-name:bounce_circularG;
        -o-animation-name:bounce_circularG;
        -ms-animation-name:bounce_circularG;
        -webkit-animation-name:bounce_circularG;
        -moz-animation-name:bounce_circularG;
    animation-duration:1.1s;
        -o-animation-duration:1.1s;
        -ms-animation-duration:1.1s;
        -webkit-animation-duration:1.1s;
        -moz-animation-duration:1.1s;
    animation-iteration-count:infinite;
        -o-animation-iteration-count:infinite;
        -ms-animation-iteration-count:infinite;
        -webkit-animation-iteration-count:infinite;
        -moz-animation-iteration-count:infinite;
    animation-direction:normal;
        -o-animation-direction:normal;
        -ms-animation-direction:normal;
        -webkit-animation-direction:normal;
        -moz-animation-direction:normal;
}

#circularG_1{
    left:0;
    top:23px;
    animation-delay:0.41s;
        -o-animation-delay:0.41s;
        -ms-animation-delay:0.41s;
        -webkit-animation-delay:0.41s;
        -moz-animation-delay:0.41s;
}

#circularG_2{
    left:6px;
    top:6px;
    animation-delay:0.55s;
        -o-animation-delay:0.55s;
        -ms-animation-delay:0.55s;
        -webkit-animation-delay:0.55s;
        -moz-animation-delay:0.55s;
}

#circularG_3{
    top:0;
    left:23px;
    animation-delay:0.69s;
        -o-animation-delay:0.69s;
        -ms-animation-delay:0.69s;
        -webkit-animation-delay:0.69s;
        -moz-animation-delay:0.69s;
}

#circularG_4{
    right:6px;
    top:6px;
    animation-delay:0.83s;
        -o-animation-delay:0.83s;
        -ms-animation-delay:0.83s;
        -webkit-animation-delay:0.83s;
        -moz-animation-delay:0.83s;
}

#circularG_5{
    right:0;
    top:23px;
    animation-delay:0.97s;
        -o-animation-delay:0.97s;
        -ms-animation-delay:0.97s;
        -webkit-animation-delay:0.97s;
        -moz-animation-delay:0.97s;
}

#circularG_6{
    right:6px;
    bottom:6px;
    animation-delay:1.1s;
        -o-animation-delay:1.1s;
        -ms-animation-delay:1.1s;
        -webkit-animation-delay:1.1s;
        -moz-animation-delay:1.1s;
}

#circularG_7{
    left:23px;
    bottom:0;
    animation-delay:1.24s;
        -o-animation-delay:1.24s;
        -ms-animation-delay:1.24s;
        -webkit-animation-delay:1.24s;
        -moz-animation-delay:1.24s;
}

#circularG_8{
    left:6px;
    bottom:6px;
    animation-delay:1.38s;
        -o-animation-delay:1.38s;
        -ms-animation-delay:1.38s;
        -webkit-animation-delay:1.38s;
        -moz-animation-delay:1.38s;
}



@keyframes bounce_circularG{
    0%{
        transform:scale(1);
    }

    100%{
        transform:scale(.3);
    }
}

@-o-keyframes bounce_circularG{
    0%{
        -o-transform:scale(1);
    }

    100%{
        -o-transform:scale(.3);
    }
}

@-ms-keyframes bounce_circularG{
    0%{
        -ms-transform:scale(1);
    }

    100%{
        -ms-transform:scale(.3);
    }
}

@-webkit-keyframes bounce_circularG{
    0%{
        -webkit-transform:scale(1);
    }

    100%{
        -webkit-transform:scale(.3);
    }
}

@-moz-keyframes bounce_circularG{
    0%{
        -moz-transform:scale(1);
    }

    100%{
        -moz-transform:scale(.3);
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

@media (max-width: 1199px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
    body.opened{
        margin-right: 0;
    }
}

@media (max-width: 991px) {
    .simple-form{
        padding: 0;
        height: auto;
    }
    .simple-form::before,
    .simple-form__left{
        display: none; 
    }
    .simple-form__content{
        padding: 15px 0;
    }
    .simple-form form{
        max-width: 290px;
        margin: 0 auto;
    }
    .simple-form b{
        display: block;
        margin:0 -15px;
        background-color: $color-primary;
        padding: 15px;
    }
    .simple-form form input[type=submit]{
        width: 100%;
    }
    div.load::after, div.success::after{
        background-color: #55b5f2;
    }
    div.load .circularG, div.success .circularG{
        background-color: #fff;
    }
}
@media (max-width: 757px) {
    .simple-form b {
        font-size: 24px;
        line-height: 26px;
    }
    .simple-form span{
        font-size: 20px;
        line-height: 24px;
    }
    .simple-form form{
        padding: 20px 0px 0;
    }
    .simple-form form input[type=submit]{
        height: 40px;
    }
    .simple-form__group label{
        font-size: 16px;
    }
}

