a.modal_close{
	display: block;
	position: absolute;
	z-index: 205;
	top: 10px;
	right: 10px;
	width: 18px;
	height: 18px;
	&:after, &:before{
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 2px;
		background-color: #fff;
		border-radius: 3px;
		transition: .3s;
		opacity: 0.4;
	}
	&:after{
		transform: rotate(45deg);
	}
	&:before{
		transform: rotate(-45deg);
	}
	&:hover{
		&:after, &:before{
			opacity: 1;
		}
	}
}
i.modal_bg{
	display: none;
	position: fixed;
	z-index: 8999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	&.opened{
		display: block;
	}
}

.custom_modal{
	display: block;
	position: fixed;
	z-index: 9000;
	top: 100px;
	left: 0;
	right: 0;
	margin: auto;
	width: 320px;
	max-width: 100%;
	max-height: 100%;
	background-color: #fff;
	transform: translateY(-300%);
	transition: .3s;
	&.opened{
		transform: translateY(0%);
	}
	.modal_title{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 118px;
		background-image: url('../images/modal-title.jpg');
		background-position: center;
		background-size: cover;
		span{
			color: #ffffff;
			font-size: 30px;
			font-weight: 700;
			line-height: 36px;
		}
	}
	form{
		padding: 13px 30px 25px;
		&.load{
			position: relative;
			.load{
				display: flex;
				&::after{
					background-color: #fff;
					opacity: 0.8;
				}
			}
		}
		&.success{
			.success{
				display: flex;
				background-color: rgba(255,255,255,1);
				b{
				    font-size: 36px;
				    text-align: center;
				    line-height: 40px;
				}
				&::after{
					background-color: #fff;
					opacity: 0.8;
				}
			}	
		}
		.form-group{
			position: relative;
			margin-bottom: 10px;
			input,textarea{
				border-radius: 4px;
				border: 1px solid #e5e5e5;
				width: 100%;
				outline: none;
				color: $color-text;
				font-size: 16px;
				font-weight: 400;
				padding: 8px 15px;
				&.error{
					border-color: #e73f54;
					& + label{
						color: #e73f54;
					}
				}
			}
			input{
				height: 50px;
			}
			textarea{
				resize: none;
				height: 160px;
			}
		}

		button[type="submit"]{
			width: 100%;
		    margin: 3px 0 14px;
		    cursor: pointer;
		    border-radius: 22px;
		    background-color: #f4f4f4;
		    border: none;
		    outline: none;
		    height: 50px;
		    position: relative;
		    transition: $time;
			span{
				background-color: #fff;
				border: 1px solid $color-hover;
				color: $color-hover;
			    font-size: 16px;
			    font-weight: 700;
			    height: calc(100% - 6px);
			    width: calc(100% - 6px);
			    border-radius: 25px;
			    position: relative;
			    left: 3px;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    transition: $time;
			}
			&:hover{
				background-color: transparent;
				span{
					background-color: $color-hover;
					color: #fff;
				}
			}
		}

		.personal{
			color: #9b9b9b;
			font-size: 14px;
			font-weight: 400;
			a.personal{
				display: inline-block;
				border-bottom: 1px solid #9b9b9b;
				transition: $time;
				&:hover{
					border-color: transparent; 
				}
			}
		}
	}
}

@media (max-width: 1199px) {
	.custom_modal {
		top: 10px;
		max-height: calc(100% - 20px);
		overflow-y: scroll;
	  }
}