.contacts-block {
	padding-left: 30px;
	background-color: #fff;
	&__title {
		display: block;
		padding: 0 0 16px;
		color: $color-text;
		font-size: 25px;
		font-weight: 600;
	}
	&__info {
		padding: 20px 0 0;
	}
	&__map-container {
		height: 400px;
	}
}

@media (max-width: 767px) {
	.contacts-block {
		padding: 0 0 20px;
		&__map-container {
			height: 230px;
		}
	}
}