.footer{
	border-top: 2px solid #d7d7d7;
	padding-top: 15px;
	background-color: $color-bg;
	&-top{
		padding-bottom: 58px;
		&__logo{
			width: 152px;
			height: 88px;
			fill: #cacaca;
		}
		&__content{
			padding-top: 34px;
			p{
				display: inline-block;
				color: $color-text;
				font-size: 20px;
				font-weight: 400;
				border-bottom: 1px solid #36a7ef;
				margin-bottom: 18px;
				line-height: 40px;
			}
			span{
				display: block;
				color: #8e8e8e;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;

				&.address{
					padding-right: 50px;
					margin-bottom: 14px;
				}
			}
			ul{
				li{
					margin-bottom: 14px;
				}
			}
			a{
				display: block;
				color: #8e8e8e;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				transition: .2s;
				&:hover{
					color: $color-hover;
				}

				&.mail{
					margin-top: 12px;
					display: inline-block;
					border-bottom: 1px solid  #8e8e8e;
					line-height: 24px;
				}
			}
		}
	}
	&-bottom{
		background-color: #e8e8e8;
		padding: 20px 0;
		&__wrapper{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			&-left{
				span, a{
					color: #a7a7a7;
					font-size: 14px;
					font-weight: 400;
					line-height: 16px;
				}
				a{
					display: inline-block;
					border-bottom: 1px solid #a7a7a7;
					margin: 0 48px;
					transition: $time;
					&:hover{
						color: $color-hover;
						border-color: $color-hover;
					}
				}
			}
			&-right{
				a{
					color: #a7a7a7;
					font-size: 14px;
					font-weight: 400;
					line-height: 16px;
					svg{
						margin-left: 3px;
						width: 107px;
						height: 22px;
					}
				}
			}
		}
	}
	&-btn{
		display: flex !important;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 240px;
		height: 44px;
		border-radius: 22px;
		margin-bottom: 5px;
		margin-top: 9px;
		float: right;
		transition: $time;
		svg{
			width: 14px;
			height: 14px;
			margin-right: 10px;
			transition: $time;
		}
		b{
			font-size: 16px;
			font-weight: 700;
			transition: $time;
		}
		&.call{
			border: 1px solid $color-hover;
			background-color: $color-hover;
			b{
				color: #fff;	
			}
			svg{
				fill: #fff;
			}
			&:hover{
				background-color: transparent;
				border-color: #36a7ef;
				b{
					color: #36a7ef;
				}
				svg{
					fill: #36a7ef;
				}
			}
		}
		&.price{
			border: 1px solid #36a7ef;
			b{
				color: #36a7ef;
			}
			svg{
				fill: #36a7ef;
			}
			&:hover{
				border-color: $color-hover;
				background-color: $color-hover;
				b{
					color: #fff;
				}
				svg{
					fill: #fff;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.footer-hidden{
		display: none;
	}
}

@media (max-width: 575px) {
	.footer-top__content {
	    padding-top: 10px;
	}
}

@media (max-width: 483px){
	.footer-bottom__wrapper-left a{
		display: block;
		margin: 3px 0;
	}
}

