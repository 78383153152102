.sort-parameters{
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
	margin-bottom: 28px;
	border-left: 1px solid #d8d8d8;
	border-right: 1px solid #d8d8d8;
	&__order-by{
		color: #888888;
		font-size: 16px;
		font-weight: 400;
		flex: 0 0 50%;
		select{
			border: none;
			outline: none;
			color: #0e354d;
			font-size: 16px;
			line-height: 21px;
			appearance: none;
			background: transparent;
			cursor: pointer;
		}
	}
	&__total-products{
		span, i{
			color: #ababab;
			font-size: 15px;
			font-weight: 400;
			font-style: normal;
		}
	}
}

@media (max-width: 420px){
	.sort-parameters__order-by,
	.sort-parameters__order-by select,
	.sort-parameters__total-products span, .sort-parameters__total-products i{
		font-size: 14px;
	}
}