.product-form{
	&__title{
		color: #373a3c;
		font-size: 30px;
		font-weight: 700;
		line-height: 38px;
		margin-bottom: 26px;
	}
	.no-sizes{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		padding: 25px 0;
		span{
			color: #a9a9a9;
			font-size: 18px;
		}
		b{
			color: $color-primary;
			font-size: 18px;
		}
	}
	.sizes-table{
		background-color: #fff;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11);
		margin-bottom: 30px;
		&__row{
			display: flex;
    		flex-direction: row;
    		position: relative;
    		&:last-child{
    			border-bottom: none;
    		}
    		&-col{
    			display: flex;
    			align-items: center;
    		}
			&-size{
				flex: 0 0 13%;
			}
			&-case{
				flex: 1;
			}
			&-price{
				flex: 0 0 16%;
			}
			&-amount{
				flex: 0 0 12%;
			}
			&-add{
				flex: 0 0 27%;
				button{
					width: 150px;
					height: 45px;
					margin-left: 15px;
					border-radius: 22px;
					background-color: transparent;
					position: relative;
					border: none;
					cursor: pointer;
					transition: $time;
					outline: none;
					&.added{
						&::after{
							content: 'Добавлено';
						    display: flex;
						    justify-content: center;
						    align-items: center;
						    font-size: 16px;
						    font-weight: 700;
						    color: #fff;
						    background-color: #ff714b;
						    position: absolute;
						    width: 100%;
						    height: 100%;
						    border-radius: 75px;
						}
					}
					span{
						display: flex;
						justify-content: center;
						align-items: center;
						width: calc(100% - 10px);
						height: 38px;
						border: 1px solid $color-hover;
						border-radius: 22px;
						background-color: #ffffff;
						color: $color-hover;
						font-size: 16px;
						font-weight: 700;
						transition: $time;
					}
					&:hover{
						background-color: transparent;
						span{
							background-color: $color-hover;
							color: #fff;
						}
					}
				}

			}
			span, b{
				color: $color-text;
				font-size: 14px;
			}
			b{
				font-size: 16px;
			}
		}
		&__head{
			border-bottom: 1px solid #e6e6e6;
			padding: 3px 20px;
			span{
				color: $color-primary;
				font-size: 13px;
				font-weight: 700;
			}
		}
		&__body{
			margin: 12px 8px 0 21px;
			padding-bottom: 13px;
    		border-bottom: 1px solid #e6e6e6;
		}
	}

	&__shipping{
		background-color: #fff;
		display: flex;
		flex-direction: row;
		padding: 20px 10px;
		div{
			flex: 1;
			display: flex;
    		border-right: 1px solid #e6e6e6;
    		margin-left: 25px;
    		&:last-child{border-right: none;}
			img{
				width: 48px;
				height: 35px;
				float: left;
				object-fit: contain;
				margin-right: 15px;
			}
			p{
				color: #727272;
				font-size: 15px;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}
}
@media (max-width: 1199px){
	.product-form__shipping{
		margin-bottom: 30px;
	}
}
@media (max-width: 575px){
	.product-reverse{
		flex-direction: column-reverse;
	}
	.product-form .sizes-table__head{
		display: none;
	}
	.product-form .sizes-table__row{
		flex-direction: column;
		padding: 20px 15px;
		margin: 0;
	}
	.product-form .sizes-table__row-col{
		padding: 5px 0;
	}
	.product-form .sizes-table__row-add{
		position: absolute;
	    bottom: 15px;
	    left: 115px;
	}
	.product-form .sizes-table__row-add button{
		margin-left: 0;
	}
	.product-form__shipping{
		flex-direction: column;
		padding: 0;
	}
	.product-form__shipping div{
		padding: 25px;
		border-right: none;
	    border-bottom: 1px solid #e6e6e6;
	    margin-left: 0;
	}
}