.checkout-form{
	box-shadow: none;
	padding: 0;
	width: 100%;
	transition: .3s;
	&.loading{
		pointer-events: none;
		opacity: 0.5;
	}
	&__form-part{
		padding: 0 0 15px;
		margin-bottom: 30px;
		border-radius: 4px;
		border: 1px solid #e5e5e5;
		background-color: #ffffff;
		&-title{
			color: #373a3c;
			font-size: 21px;
			font-weight: 700;
			border-bottom: 1px solid #e5e5e5;
			background-color: #fff;
			padding: 10px 25px;
			margin-bottom: 20px;
			span{
				font-size: 18px;
				color: $color-primary;
				padding-right: 2px;
			}
		}
		&-body{
			padding: 0 50px;
		}
	}
	&__payment-type{
		padding: 12px 50px 15px;
		.payment_checkbox{
			cursor: pointer;
			input{
				display: none;
			}	
			input:checked + .new_checkbox{
				background-color: $color-primary;
				border: 1px solid $color-primary;
				color: #fff;
			}
			.new_checkbox{
				background-color: transparent;
				border-radius: 4px;
				color: $color-primary;
				font-size: 16px;
				font-weight: 700;
				line-height: 21px;
				text-decoration: none;
				padding: .7rem 1rem;
				transition: $time;
				border: 1px solid #e5e5e5;
				&:hover{
					background-color: $color-primary;
					border-color: $color-primary;
					color: #fff;
				}
			}
		}
	}
	&__courier-info{
		&-row{
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			border-bottom: 1px dotted #9b9b9b;
			margin-bottom: 10px;
			padding: 9px 0 0;
			span{
				display: block;
				margin: 0 0 -5px;
				background-color: #fff;
				color: #3a372e;
				font-size: 16px;
				font-weight: 400;
				max-width: 65%;
				&.title{
					color: #999;
				}
				&:last-child{
					text-align: left;
				}
			}
		}
	}
	&__delivery-types{
		padding: 4px 50px;
		&-attention{
			color: #999999;
			font-size: 13px;
			font-weight: 400;
			margin-bottom: 25px;
			svg{
				margin: -3px 5px 0;
				width: 18px;
				height: 18px;
				float: left;
				fill: #999999;
			}
		}
		ul{
			padding-bottom: 20px;
			border: none;
			.nav-link{
				border: none;
				color: $color-primary;
				border: 1px solid #e5e5e5;
				border-radius: 5px;
				font-size: 16px;
				font-weight: 700;
				line-height: 21px;
				transition: $time;
				margin-right: 3px;
				&.active{
					border-radius: 4px;
					background-color: $color-primary;
					border: 1px solid $color-primary;
					color: #fff;
				}
				&:hover{
					background-color: $color-primary;
					border-color: $color-primary;
					color: #fff;
				}
			}
		}
		
		.tab-pane{
			display: none;
			padding: 0;
			&.active{
				display: block;
			}
			.form_group{
				padding: 0 0 16px;
			}
			.inputs_row{
				display: flex;
				justify-content: space-between;
				&.double{
					.form_group, .inputs_row{
						width: calc(100% / 2 - 15px);
						min-width: calc(100% / 2 - 15px);
					}
				}
				&.triple{
					.form_group, .inputs_row{
						width: calc(100% / 3 - 5px);
						min-width: calc(100% / 3 - 5px);
					}
				}
			}
		}
	}
	&__map-container{
		height: 400px;
		margin: 10px 0 20px;
	}
	&__footer{
		display: flex;
		justify-content: space-between;
		padding: 22px 0;
		.policy{
			width: 330px;
			max-width: 100%;
			color: $color-text;
			font-size: 15px;
			font-weight: 400;
			line-height: 22px;
			a{
				color: $color-primary;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.button{
			max-width: 100%;
			button{
				outline: none;
				width: 370px;
				height: 55px;
				max-width: 100%;
				border-radius: 30px;
				background-color: $color-hover;
				border: 1px solid $color-hover;
				color: #ffffff;
				font-size: 18px;
				font-weight: 900;
				cursor: pointer;
				transition: $time;
				&:hover{
					color: $color-hover;
					background-color: transparent;
				}
			}
			.error_text{
				display: block;
				color: #dc3545;
				font-size: 15px;
				font-weight: 400;
				line-height: 22px;
				padding-top: 10px;
			}
		}
	}
}

@media (max-width : 767px) {
	.checkout-form__delivery-types ul li{
		margin-bottom: 10px !important;
		&:last-child{
			margin-bottom: 0 !important;
		}
	}
	.checkout-form{
		&__form-part{
			&-title{
				font-size: 16px;
				padding: 10px 15px;
			}
			&-body{
				padding: 0 15px;
			}
		}
		&__payment-type{
			padding: 5px 15px;
			.payment_checkbox{
				width: 100%;
				margin-bottom: 28px;
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
		&__courier-info{
			&-row{
				border: none;
				flex-direction: column;
				align-items: flex-start;
				margin: 0 0 5px;
				span{
					max-width: none;
				}
			}
		}
		&__delivery-types{
			padding: 0 15px;
			ul{
				li{
					border: none;
					a{
						border: none;
					}
				}
			}
		}
		&__map-container{
			height: 230px;
			margin: 10px 0 5px;
		}
		&__footer{
			flex-direction: column-reverse;
			align-items: center;
   			text-align: center;
			padding: 10px 0 0;
			.policy{
				padding: 5px 0 0;
			}
		}
	}
}