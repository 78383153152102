.product-slider{
	&__container{
		position: relative;
		height: 410px;
		width: 100%;
		border: 1px solid #e5e5e5;
		overflow: hidden;
		&-slide{
			outline: none;
			border: none;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
			}
		}
		.slick-list,
		.slick-track,
		.slick-slide,
		.slick-slide>div{
			height: 100%;
		}

		&.no-photo{
			background-color: #fff;
			margin-bottom: 30px;
			svg{
			    margin: auto;
			    width: 200px;
			    height: 200px;
			    position: absolute;
			    left: 0;
			    right: 0;
			    top: 0;
			    bottom: 0;
			    fill: $color-primary;
			}
		}
	}

	&__carousel{
		padding: 10px 0 30px;
		&-slide{
			height: 100%;
			outline: none !important;
			img{
				max-width: 100%;
				max-height: 100%;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.slick-slide{
			margin: 0 5px;
			width: 70px !important;
			overflow: hidden;
			height: 70px;
			border: 1px solid #e5e5e5;
			transition: $time;
	    	outline: none !important;
			&>div{
				height: 100%;
				outline: none !important;
			}
			&.slick-current{
				border: 2px solid #ff714b;
			}
		}
		.slick-track{
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__arrow{
		background-color: #f2f2f2;
		border-radius: 50%;
		position: absolute;
		width: 60px;
		height: 60px;
	    z-index: 9;
	    cursor: pointer;
	    transition: $time;
		&::after{
			content: '';
			display: block;
			border-bottom: 1px solid #909090;
			border-right: 1px solid #909090;
			width: 12px;
			height: 12px;
			position: absolute;
			transition: $time;
		}
		&-prev{
			top: calc(50% - 30px);
			left: -30px;
			&::after{
			    transform: rotate(135deg);
			    right: 10px;
    			top: calc(50% - 6px);
			}
		}

		&-next{
			top: calc(50% - 30px);
			right: -30px;
			&::after{
			    transform: rotate(-45deg);
			    left: 10px;
    			top: calc(50% - 6px);
			}
		}

		&:not(.slick-disabled):hover{
			background-color: $color-hover;
			&::after{
				border-color: #fff;
			}
		}
	}
}

@media (max-width: 1999px){
	.product-slider{
		max-width: 575px;
		margin: 0 auto;
	}
}

@media (max-width: 575px){
	.product-slider__container{
		height: auto;
	}
}