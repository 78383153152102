.cart-item {
    $this: &;
    width: 100%;
    height: 80px;
    // border-bottom: 1px solid #e6e6e6;
    overflow: hidden;
    position: relative;
    will-change: height;
    &::after{
        content: '';
        display: block;
        height: 1px;
        width: 95%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #e6e6e6;
    }

    &:last-child {
        // border-bottom: 0;
        &::after{
            display: none;
        }
    }

    &-link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 20px;

        &:hover {
            #{$this}-title {
                color: $color-hover;
            }
        }
    }

    &-thumb {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        margin-right: 15px;
        overflow: hidden;

        &_no-photo {
            
        }

        &__image {
            max-width: 100%;
            max-height: 100%;
        }

        &__icon {
            width: 90%;
            height: 90%;
            fill: #336db0;
        }
    }

    &-title {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: $color-text;
        transition: color 100ms ease;
        span{
            display: block;
            color: #727272;
            margin-top: 3px;
        }
    }

    &-price {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $color-text;

        &_sale {
            color: #d9534f;
        }
    }

    &-old-price {
        font-size: 13px;
        font-weight: 500;
        color: $color-caption;
        text-decoration: line-through;
    }
    
    &-total, &-currency {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $color-text;

        &-wrap {
            display: flex;
        }
    }

    &-currency {
        margin-left: 5px;
    }

    &-delete {
        position: relative;
        width: 40px;
        height: 40px;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;

        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 18px;
            height: 2px;
            background-color: #b7b7b8;
            margin: auto;
            transition: background-color 100ms ease;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }

        &:hover {
            &::before, &::after {
                background-color: $color-hover;
            }
        }
    }
}

@media(max-width: 767px) {
    .cart-item {
        height: auto;

        &-link {
            flex-direction: column;
            padding: 0;
        }

        &-thumb {
            margin-right: 0;
            margin-bottom: 10px;
        }

        &-delete {
            height: 35px;
        }
    }
}
