.paginationBlock{
	padding-top: 30px;
	.load_content{
		width: 100%;
		height: 55px;
		border-radius: 6px;
		border: 1px solid #e5e5e5;
		background-color: #ffffff;
		color: $color-primary;
		font-size: 16px;
		font-weight: 700;
		line-height: 25px;
		outline: none;
		transition: $time;
		cursor: pointer;
		&:hover{
			border-color: $color-hover;
			background-color: $color-hover;
			color: #fff;
		}
	}
	.page-item{
		.page-link{
			border-radius: 0;
			color: $color-primary;
			font-size: 16px;
			font-weight: 400;
			line-height: 21px;
		}
		&.active{
			.page-link{
				border-color: $color-hover;
				background-color: $color-hover;
				color: #fff;
			}
		}
	}
}