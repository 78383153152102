.requisite-block {
	margin: 0 0 13px;
	&__title {
		display: block;
		color: $color-primary;
		font-size: 16px;
		font-weight: 600;
	}
	&__value {
		display: block;
		color: $color-text;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		transition: $time;
		&:hover{
			color: $color-hover;
		}
	}
}