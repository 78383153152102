.index-row{
    background-color: #f4f4f4;
    &__title{
        color: $color-text;
        font-size: 36px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 38px;
    }
    &__more{
        display: block;
        text-align: center;
        margin: 28px 0px 0px;
        position: relative;
        span{
            color: $color-text;
            font-size: 18px;
            font-weight: 700;
            padding: 0 20px;
            background: #f4f4f4;
            z-index: 9;
            position: relative;
            transition: $time;
        }
        &::before{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
            background-color: $color-hover;
        }
        &:hover{
            span{
                color: $color-hover;
            }
        }
    }
    
    &.slider{
        height: 485px;
        position: relative;
        .index-slider{
            height: 100%;
            &__slide{
                height: 100%;
                background-position: center;
                background-repeat: no-repeat;
                .container{
                    height: 100%;
                    position: relative;
                }
                
                &-link{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 180px;
                    height: 180px;
                    background-color: $color-hover;
                    border: 2px solid $color-hover;
                    color: #ffffff;
                    font-size: 17px;
                    font-weight: 700;
                    border-radius: 50%;
                    right: 105px;
                    bottom: 12px;
                    position: absolute;
                    transition: $time;
                    outline: none;
                    span{
                        z-index: 9;
                    }

                    &::after{
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 0;
                        height: 0;
                        background-color: #fff;
                        transition: $time;
                        border-radius: 50%;
                    }

                    &:hover{
                        color: $color-hover;
                        &::after{
                            width: 99%;
                            height: 99%;
                            left: 1px;
                            top: 1px;
                        }
                    }

                }
            }
            &__nav{
                display: flex;
                align-items: center;
                max-width: 1200px;
                margin: 0 auto;
                position: absolute;
                top: 0;
                left: 19vw;
                // right: 0;
                height: 100%;
                width: 30px;
                ul{
                    z-index: 9;
                    li{

                        button{
                            width: 13px;
                            height: 13px;
                            border: 1px solid #1d9beb;
                            background-color: #ffffff;
                            transition: $time;
                            font-size: 0;
                            outline: none;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                        &.slick-active{
                            button{
                                background-color: #1d9beb;
                            }
                        }
                    }
                }
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    height: 85%;
                    width: 1px;
                    left: 6px;
                    top: 40px;
                    background-color: #d8d8d8;
                }
            }
        }
        .slick-list,
        .slick-track{
            height: 100%;
        }
        .slick-slide{
            &>div{
                width: 100%;
                height: 100%;
            }
        }
    }

    &.category{
        padding-top: 22px;
    }

    &.hit{
        padding-top: 63px;
        .index-row__title{
            margin-bottom: 49px;
        }
    }

    &.plus{
        padding-top: 95px;
        padding-bottom: 99px;
        .index-row__title{
            margin-bottom: 75px;
        }
        .plus-wrapper{
            display: flex;
            flex-direction: row;
            &__block{
                flex: 1;
                text-align: center;
                svg{
                    width: 87px;
                    height: 87px;
                    margin-bottom: 25px;
                }
                p{
                    color: $color-text;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 0 10px;
                }
            }
        }
    }

    &.simple-form{
    }
    
    &.advantage{
        padding: 100px 0;
        .advantage-block{
            height: 365px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.18);
            background-color: #ffffff;
            text-align: center;
            padding: 18px 14px;
            svg{
                height: 140px;
                margin-bottom: 10px;
            }
            p{
                color: $color-text;
                font-size: 22px;
                font-weight: 400;
                line-height: 28px;
                margin-bottom: 9px;
            }
            span{
                color: #888888;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                display: inline-block;
                padding: 0 15px;
            }
            &__1{
                svg{
                    width: 107px;
                    // height: 68px;
                }
            }
            &__2{
                svg{
                    width: 80px;
                    // height: 88px;
                }
            }
            &__3{
                svg{
                    width: 90px;
                    // height: 88px;
                }
            }
            &__4{
                svg{
                    width: 90px;
                    // height: 90px;
                }
            }
        }
    }
    
}

@media (max-width : 1745px) {
    .index-slider__nav{
        left: 10px !important;
    }
}

@media (max-width : 1199px) {

    // slider
    .index-row.slider{
        height: 46vw;
    }
    .index-row.slider .index-slider__slide{
        background-size: auto 100%;
    }
    .index-row.slider .index-slider__nav{
        height: 30px;
        width: 99%;
        bottom: 0;
        top: auto;
        left: 0 !important;
    }
    .index-row.slider .index-slider__nav ul{
        display: flex;
        flex-direction: row;
        margin: 0 auto;
    }
    .index-row.slider .index-slider__nav li{
        margin: 0 3px;
    }
    .index-row.slider .index-slider__nav::after{
        display: none;
    }
    .index-row.slider .index-slider__slide-link{
        width: 150px;
        height: 40px;
        font-size: 14px;
        border-radius: 22px;
        &:hover{
            color: #fff
        }
    }
    .index-row.slider .index-slider__slide-link:hover::after{
        display: none;
    }

    // title
    .index-row__title,
    .index-row.hit .index-row__title,
    .index-row.plus .index-row__title{
        margin: 25px 0;
        font-size: 32px;
    }

    // row plus
    .index-row.plus{
        padding: 25px 0;
    }
    .index-row.plus .plus-wrapper{
        flex-wrap: wrap;
        justify-content: center;
    }
    .index-row.plus .plus-wrapper__block{
        flex: 1 0 auto;
        max-width: 270px;
        margin-bottom: 30px;
    }

    // simple-form
    .simple-form__content {
        padding-left: 0px;
    }
}

@media (max-width : 991px) {
    .index-row.hit {
        padding-top: 35px;
    }

    .index-row.simple-form{
        height: auto;
    }

    .index-row.advantage {
        padding: 25px 0;
    }
    .index-row.advantage .advantage-block{
        margin-bottom: 25px;
    }
}

@media (max-width : 767px) {

}

@media (max-width : 575px) {

    // slider
    .index-row.slider .index-slider__slide-link {
        width: 130px;
        height: 28px;
        font-size: 12px;
        left: 6%;
        bottom: 20%;
    }

    // title
    .index-row__title,
    .index-row.hit .index-row__title,
    .index-row.plus .index-row__title{
        margin: 25px 0 14px;
        font-size: 20px;
        text-align: left;
    }
    .index-row.plus .index-row__title{
        text-align: center;
    }
    .index-row.advantage .advantage-block{
        height: auto;
    }
}

@media (max-width : 419px) {
    .index-row__title,
    .index-row.hit .index-row__title,
    .index-row.plus .index-row__title{
        margin-top: 12px;
    }
}