.product-helper{
	height: 200px;
    background-position: right bottom;
    background-size: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 35px 0;
    &::after{
    	content: '';
    	position: absolute;
    	left: 0;
    	top: 0;
    	display: block;
		background-color: #1d9beb;
		opacity: 0.82;
		width: 100%;
		height: 100%;
    }
    p{
		color: #ffffff;
		font-size: 31px;
		font-weight: 700;
		line-height: 35px;
		z-index: 9;
        text-align: center;
        padding: 0 15px;
    }
    a{
		width: 200px;
		height: 40px;
		border-radius: 22px;
		background-color: $color-hover;
        border: 1px solid $color-hover;
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		z-index: 9;
        transition: $time;
        &:hover{
            border-color: #fff;
            background-color: transparent;
        }
    }
}