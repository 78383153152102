// General fonts
@mixin font-h1 {
	color: #333333;
	font-size: 36px;
	font-weight: 700;
	line-height: 42px;
}

@mixin font-h2 {
	color: #333333;
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
}

@mixin font-h3 {
	color: #333333;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

@mixin font-p {
	color: #333333;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

@mixin font-sign {
	color: #999;
	font-size: 14px;
	font-weight: 400;
}



