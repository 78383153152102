.page {
	padding-bottom: 90px;
	background-color: $color-bg;
}


@media (max-width: 767px) {
	.page {
		padding-bottom: 15px;
	}
}