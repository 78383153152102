.product-tab{
	margin-top: 13px;
	padding-top: 30px;
	border-top: 1px solid #e6e6e6;
	.nav-tabs{
		border: 1px solid #ededed;
		background-color: #ffffff;
		padding: 10px 14px 0;
		.nav-item{
			margin-bottom: 0;
			padding: 8px 14px 0;
			.nav-link{
				border: none;
				border-bottom: 4px solid transparent;
				color: $color-text;
				font-size: 16px;
				font-weight: 700;
				padding: 0 0 12px;
				&.active{
					border-bottom: 4px solid $color-hover;
				}
			}
		}
	}
	.tab-content{
		padding: 18px 30px;
	}
}

.tab-pane__characteristic-row{
	display: flex;
	flex-direction: row;
	padding: 3px 0;
	b{
		flex: 0 0 50%;
	}
	span{
		flex: 0 0 50%;
	}
}

@media (max-width : 767px) {
	.tab-pane{
		padding: 10px 2px;
	}
}

@media (max-width : 575px) {
	.tab-pane{
		&__characteristic-row{
			flex-direction: column;
			padding: 8px 0 0;
			&-title{
				width: 100%;
			}
			&-value{
				font-size: 14px;
			}
		}
	}
}