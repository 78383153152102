.extra-products-slider{
	padding: 30px 0 20px;
	&.second{
		padding-top: 0px;
	}
	&.active &{
		&__slider-header{
			.nav-buttons{
				display: flex;
			}
		}
	}
	&__slider-header{
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 0 18px 16px;
		&-title{
			display: block;
			color: #373a3c;
			font-size: 24px;
			font-weight: 600;
		}
		.nav-buttons{
			display: none;
			justify-content: space-between;
			width: 90px;
			a{
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				border-radius: 6px;
				border: 1px solid #d6d6d6;
				background-color: transparent;
				transition: .3s;
				&:hover{
					border-color: $color-hover;
					background-color: $color-hover;;
					> svg{
						fill: #fff;
					}
				}
				&.slick-hidden{
					display: none;
				}
				&.next{
					> svg{
						transform: rotate(180deg);
					}
				}
				> svg{
					fill: #9b9b9b;
					transition: .3s;
				}
			}
		}
	}
	&__slider-body{
		overflow: hidden;
		.slick-list{
			overflow-y: visible;
			overflow-x: hidden;
			.slick-track{
				min-width: 100%;
				// padding-top: 15px;
			}
		}
		.slick-slide{
			padding: 0 15px;
		}
	}
	&__no-slider{
		padding: 15px 0;
	}

	.slick-dots{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: -10px;
		li{
			button{
				transition: $time;
				background-color: #fff;
			    outline: none;
			    border: 1px solid $color-primary;
			    border-radius: 50%;
			    font-size: 0;
			    width: 10px;
			    height: 10px;
			    margin: 0 3px;
			}
			&.slick-active{
				button{
					background-color: $color-hover;
					border-color: $color-hover;
				}
			}
		}
		
	}
}
@media(max-width: 767px){
	.extra-products-slider.active .extra-products-slider__slider-header .nav-buttons{
		display: none;
	}
}

@media(max-width: 575px){
	.extra-products-slider{
		padding: 15px 0;
		&__slider-header{
			flex-direction: column;
			align-items: flex-start;
			&-title{
				font-size: 22px;
				padding-bottom: 5px;
			}
		}
	}
	.extra-products-slider__slider-header{
		padding: 0 0 16px;
	}
	.extra-products-slider__slider-body .slick-slide{
		padding: 0;
	}
	.extra-products-slider .product-card__wrapper{
		box-shadow: none;
	}
}